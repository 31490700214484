// This file collects all our constants.
// Objects like PATHS and LABELS are intended to function as barrels for easy importing
import { GfSnsData, GfLrosData, NavItemData } from './types';

// Nuxt lacks a good way to refer to routes by name. These path constants give us a central place to keep track of our paths in a maintainable way. "Paths" refer to internal nuxt paths.
export const HOME_PATH = '/';
export const ABOUT_PATH = '/about';
export const TEAM_PATH = '/team';
export const CONTACT_PATH = '/contact';
export const AMENDMENTS_PATH = '/amendments';
export const CULTURE_PATH = '/culture';
export const PRIVACY_POLICY_PATH = '/privacy';
export const TERMS_OF_SERVICE_PATH = '/terms';

export const PATHS = {
	HOME_PATH,
	ABOUT_PATH,
	TEAM_PATH,
	CONTACT_PATH,
	CULTURE_PATH,

	PRIVACY_POLICY_PATH,
	TERMS_OF_SERVICE_PATH,

	AMENDMENTS_PATH,
};

// "Urls" are external urls
export const BASE_DOMAIN = 'groundfloor.us';
export const FAQ_URL = `https://support.${ BASE_DOMAIN }/en/`;
export const SUPPORT_URL = `http://support.${ BASE_DOMAIN }/`;
export const BLOG_URL = `http://blog.${ BASE_DOMAIN }/`;
export const SEC_URL = 'https://www.sec.gov/';
export const SEC_FILINGS_URL = 'https://investors.groundfloor.com/groundfloor-sec-filings';
export const OFFERING_CIRCULAR_URL = 'https://www.sec.gov/Archives/edgar/data/1694600/000110465919064771/tv532767_partiiandiii.htm';
export const TWITTER_URL = 'https://twitter.com/groundfloor_com';
export const FACEBOOK_URL = 'https://www.facebook.com/groundfloorfinance/';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/groundfloor-com/mycompany/';
export const INSTAGRAM_URL = 'https://www.instagram.com/groundfloor_com/';
export const YOUTUBE_URL = 'https://www.youtube.com/channel/UC7eKJSzwkRcWB85AIrIOaqQ';
export const FUNDING_URL = '/app/investments';
export const FUNDED_URL = '/education/funded';
export const REPAID_URL = '/education/repaid';
export const INVESTOR_AGREEMENT_URL = 'https://groundfloor-assets.s3.amazonaws.com/Investor%20Agreement%202018.pdf';
export const BORROWERS_URL = `https://borrowers.${ BASE_DOMAIN }/`;
export const BROKERS_URL = '/brokers';
export const OPEN_ROLES_URL = 'https://apply.workable.com/groundfloor';
export const BBB_URL = 'https://www.bbb.org/';
export const PRESS_URL = 'http://press.groundfloor.com/';

// Rails Paths
export const RAILS_PERSONAL_INFO_PATH = '/myaccount/user_settings/edit';
export const RAILS_SECURITY_PATH = '/myaccount/user_settings_securities';
export const RAILS_BANK_ACCOUNTS_PATH = '/account_settings/bank_accounts';
export const RAILS_FUNDS_XFER_PATH = '/myaccount/funds_transfer_schedules';
export const RAILS_AUTO_INVESTING_PATH = '/myaccount/investment_schedule/edit';
export const RAILS_BENEFICIARIES_PATH = '/account_settings/beneficiaries';
export const RAILS_DOCUMENTS_PATH = '/myaccount/user_settings_documents';
export const RAILS_COM_PREFS_PATH = '/myaccount/user_settings_comm_settings';
export const RAILS_TAX_CENTER_PATH = '/account_settings/taxes';
export const RAILS_ANCHOR_INVESTOR_PATH = '/accreditations/edit';

// IWA Paths
export const IWA_PERSONAL_INFO_PATH = '/settings/personal-information';
export const IWA_SECURITY_SETTINGS_PATH = '/settings/security';
export const IWA_FUNDS_XFER_SCHEDULE_PATH = '/settings/funds-transfer-schedule';
export const IWA_BENEFICIARIES_PATH = '/settings/beneficiaries';
export const IWA_DOCUMENTS_PATH = '/settings/documents';
export const IWA_COM_PREFS_PATH = '/settings/communication-preferences';
export const IWA_TAX_CENTER_PATH = '/settings/tax-center';
export const IWA_TAX_FORMS_PATH = IWA_TAX_CENTER_PATH + '/tax-forms';
export const IWA_TAX_REPORTING_INFO_PATH = IWA_TAX_CENTER_PATH + '/tax-info';
export const IWA_BANK_ACCOUNT_LIST_PATH = '/settings/bank-account';
export const IWA_AUTO_INVESTING_PATH = '/auto-investing';
export const IWA_ANCHOR_INVESTOR_PATH = '/settings/anchor-investor';
export const IWA_FUNDS_XFER_LIST_PATH = '/settings/funds-transfers';
export const IWA_RECURRING_TRANSFERS_PATH = '/settings/funds-transfer-schedules';
export const IWA_INVESTOR_AUTO_SETTINGS_PATH = '/settings/investor-auto';
export const IWA_REFERRALS_SETTINGS_PATH = '/settings/referral-rewards';

export const EXTERNAL_URLS = {
	FAQ_URL,
	SUPPORT_URL,
	BLOG_URL,
	BORROWERS_URL,
	SEC_URL,
	SEC_FILINGS_URL,
	BBB_URL,
	OPEN_ROLES_URL,
	OFFERING_CIRCULAR_URL,
	BROKERS_URL,
	INVESTOR_AGREEMENT_URL,
	REPAID_URL,
	FUNDED_URL,
	FUNDING_URL,
	YOUTUBE_URL,
	INSTAGRAM_URL,
	LINKEDIN_URL,
	TWITTER_URL,
	FACEBOOK_URL,
	PRESS_URL,
};

export const SUPPORT_PHONE_NUMBER = '4048509223';
// NOTE: email address remains .us as emails will be migrated separately from other content
export const SUPPORT_EMAIL_ADDRESS = 'support@groundfloor.us';

export const BANNED_COUNTRY_CODES = [
	'KP', // North Korea
	'IR', // Iran
	'SY', // Syria
	'RU', // Russia
	'VE', // Venezuela
];
