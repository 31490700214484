import { minLength, required } from '@vuelidate/validators';

export const passwordMinLength = 8;

export function containsUppercase(value: string): boolean {
	return /[A-Z]/.test(value);
}

export function containsLowercase(value: string): boolean {
	return /[a-z]/.test(value);
}

export function containsNumber(value: string): boolean {
	return /[0-9]/.test(value);
}

export function containsSymbol(value: string): boolean {
	return /[#?!@$%^&*-]/.test(value);
}

export const passwordValidators = {
	required,
	minLength: minLength(passwordMinLength),
	containsUppercase,
	containsLowercase,
	containsNumber,
	containsSymbol,
};
