import Vue from 'vue';
import { Route } from 'vue-router';

import SettingsMenu from '@/components/settings-menu/SettingsMenu.component.vue';
import {
	defaultParams,
	generateMockDataPool,
	mockQuery,
	MockQueryParams,
	TestData,
} from '@/utils/mock-table-data';

export default Vue.extend({
	name: 'TableLayout',
	components: {
		SettingsMenu,
	},
	beforeRouteUpdate(to, from, next) {
		this.getMockData(this.getParamsFromRoute(to))
			.finally(() => {
				// This handles a sorting sync issue where changes from one table don't make it to the other. Thankfully this isn't something that would come up in the real world. I hope...
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				this.$refs[this.CLIENT_TABLE_REF].initSort();
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				this.$refs[this.SERVER_TABLE_REF].initSort();
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				this.$refs[this.SPLIT_TABLE_REF].initSort();
			});
		next();
	},
	data() {
		return {
			currentUrl: window.location.href,
			toggles: {
				personalInfo: true,
				security: true,
				bankAccounts: true,
				fundsXfer: true,
				autoInvesting: true,
				beneficiaries: true,
				documents: true,
				comPrefs: true,
				taxCenter: true,
			},
			poolSize: 106,
			perPage: defaultParams.perPage,
			isLoading: true,
			tableColumns: [
				{
					field: 'id',
					label: '#',
					sortable: true,
				},
				{
					field: 'foo',
					label: 'Foo',
					sortable: true,
				},
				{
					field: 'bar',
					label: 'Bar',
					sortable: true,
				},
				{
					field: 'fiz',
					label: 'Fiz',
					sortable: true,
				},
			],
			backendResults: [] as TestData[],
			backendTotal: 0,
			backendPage: defaultParams.page,
			backendSortField: defaultParams.sortField,
			backendSortOrder: defaultParams.sortOrder,
			SPLIT_TABLE_REF: 'split-table',
			CLIENT_TABLE_REF: 'client-table',
			SERVER_TABLE_REF: 'server-table',
			currentPage: 1,
		};
	},
	computed: {
		dataPool(): TestData[] {
			return generateMockDataPool(this.poolSize);
		},
	},
	mounted() {
		this.getMockData(this.getParamsFromRoute(this.$route));
	},
	methods: {
		getParamsFromRoute(route: Route): MockQueryParams {
			return {
				perPage: this.perPage,
				page: Number(route.query.page) || defaultParams.page,
				sortOrder: route.query.order ? String(route.query.order) : defaultParams.sortOrder,
				sortField: route.query.sort ? String(route.query.sort) : defaultParams.sortField,
			};
		},
		getMockData(params: MockQueryParams): Promise<void> {
			return new Promise((resolve) => {
				this.isLoading = true;
				this.backendPage = params.page;
				this.backendSortField = params.sortField;
				this.backendSortOrder = params.sortOrder;

				mockQuery(this.dataPool, params)
					.then(({ count, results }) => {
						this.backendResults = results;
						this.backendTotal = count;
					})
					.catch((e) => {
						console.error(e);
					})
					.finally(() => {
						this.isLoading = false;
						resolve();
					});
			});
		},
		handlePageChange(page: number): void {
			console.warn('???', page);
			this.$router.push({
				query: {
					page: String(page),
					sort: this.backendSortField,
					order: this.backendSortOrder,
				},
			});
		},
		handleSortChange(sort: string, order: string) {
			this.$router.push({
				query: {
					sort,
					order,
					page: String(this.backendPage),
				},
			});
		},
	},
});
