import Vue, { PropOptions } from 'vue';

import { CY_ID_ATTRIBUTE } from './../../constants/cypress';
import { StringMap } from './../../types';

export default Vue.extend({
	name: 'BasicLayout',
	props: {
		logoCyId: {
			type: String,
			default: () => '',
		} as PropOptions<string>,
	},
	computed: {
		logoCy(): StringMap<string | undefined> {
			return {
				[CY_ID_ATTRIBUTE]: this.logoCyId ? this.logoCyId : undefined,
			};
		},
	},
	methods: {
		handleLogoClick(): void {
			this.$emit('logo-click');
		},
	},
});
