import { LD_ACCOUNT_TYPES } from '@gffinance/conduit';
import Vue, { PropOptions } from 'vue';

import { LendingNavigationConfig } from './../../types';
import { getAccountLabel, stripAccountIdPrefix } from './../../utils/lending';

export default Vue.extend({
	name: 'LendingNavigation',
	props: {
		config: {
			type: Object,
			required: true,
		} as PropOptions<LendingNavigationConfig>,
		user: {
			type: Object,
			required: true,
		},
		activeAccount: {
			type: Object,
			required: true,
		},
		currentUrl: {
			type: String,
			default: () => window.location.href,
		} as PropOptions<string>,
	},
	computed: {
		accountId(): string {
			return stripAccountIdPrefix(this.activeAccount.id);
		},
		isBrokerActive(): boolean {
			return this.activeAccount.type === LD_ACCOUNT_TYPES.BROKER;
		},
		isBorrowerActive(): boolean {
			return this.activeAccount.type === LD_ACCOUNT_TYPES.BORROWER;
		},
		accountLabel(): string {
			return this.activeAccount.nickname || `${ getAccountLabel(this.activeAccount) } Account`;
		},
	},
	methods: {
		handleClose(): void {
			this.$emit('close');
		},
		handleHelp(): void {
			this.$emit('help');
		},
	},
});
