<template>
	<transition name="fade">
		<article
			class="gf-message"
			:class="[variant, size]"
		>
			<slot />
		</article>
	</transition>
</template>

<script>
export default {
	name: 'GfMessage',
	props: {
		variant: {
			type: String,
			default: 'dark',
		},
		size: {
			type: String,
			default: '',
		},
	},
};
</script>

<style lang="scss" scoped>
@import './../scss/imports';

.gf-message {
	padding: 16px 20px;
	border-radius: 4px;
	border-width: 0 0 0 4px;
	border-style: solid;
}

.dark {
	background-color: $white;
	border-color: $grey-dark;
}

.info {
	background-color: $grey-lighter;
	border-radius: 0;
	border-color: $gf-blue-dark;
	color: $grey-dark;
}

.small {
	font-size: $size-8;
}
</style>
