import Vue, { PropOptions } from 'vue';

export default Vue.extend({
	name: 'TransitionalMenuItem',
	props: {
		label: {
			type: String,
			required: true,
		} as PropOptions<string>,
		url: {
			type: String,
			default: () => '',
		} as PropOptions<string>,
		routeName: {
			type: String,
			default: () => '',
		} as PropOptions<string>,
		currentUrl: {
			type: String,
			required: true,
		} as PropOptions<string>,
		icon: {
			type: String,
			default: () => undefined,
		} as PropOptions<string | undefined>,
	},
});
