import Vue, { PropOptions } from 'vue';

import { UserMenuItem } from './../../types';

export default Vue.extend({
	name: 'UserMenu',
	props: {
		menuItems: {
			type: Array,
			required: true,
		} as PropOptions<UserMenuItem[]>,
		currentUrl: {
			type: String,
			required: true,
		} as PropOptions<string>,
	},
	methods: {
		handleLogout(): void {
			this.$emit('logout');
		},
	},
});
