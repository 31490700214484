
// This is a clone of buefy's navbar dropdown, modified to support typescript and with the standard directive swapped out for one that supports the shadow dom. Sadly there's no way to override just the directive.
import Vue from 'vue';

import clickOutside from './../directives/shadowDomClickOutside';

export default Vue.extend({
	name: 'GfNavbarDropdown',
	directives: {
		clickOutside,
	},
	inheritAttrs: false,
	props: {
		label: {
			type: String,
			default: '',
		},
		hoverable: Boolean,
		active: Boolean,
		right: Boolean,
		arrowless: Boolean,
		boxed: Boolean,
		closeOnClick: {
			type: Boolean,
			default: true,
		},
		collapsible: Boolean,
		tag: {
			type: String,
			default: 'a',
		},
	},
	data() {
		return {
			newActive: this.active,
			isHoverable: this.hoverable,
			// eslint-disable-next-line vue/no-reserved-keys
			_isNavbarDropdown: true, // Used internally by NavbarItem
		};
	},
	watch: {
		active(value) {
			this.newActive = value;
		},

		newActive(value) {
			this.$emit('active-change', value);
		},
	},
	methods: {
		toggleMenu() {
			this.newActive = !this.newActive;
		},
		showMenu() {
			this.newActive = true;
		},
		/**
		 * See naming convetion of navbaritem
		 */
		closeMenu() {
			this.newActive = !this.closeOnClick;
			if (this.hoverable && this.closeOnClick) {
				this.isHoverable = false;
			}
		},
		checkHoverable() {
			if (this.hoverable) {
				this.isHoverable = true;
			}
		},
	},
});
