import Vue, { PropOptions } from 'vue';

import {
	SUPPORT_EMAIL_ADDRESS,
	SUPPORT_PHONE_NUMBER,
	SUPPORT_URL,
} from './../../constants';
import { formatPhone, getDomainUrl, getSettings } from './../../utils';

export interface SettingMenuItem {
	icon: string;
	iwaPath?: string;
	railsPath?: string;
	label: string;
	enableIwa: boolean;
	text?: string;
	cta: string;
	button: string;
	subItems?: SettingMenuItem[];
	noteMessage?: string;
}

export interface SettingsMenuFeatureToggles {
	personalInfo: boolean;
	security: boolean;
	bankAccounts: boolean;
	fundsXfer: boolean;
	fundsXferSchedule: boolean,
	autoInvesting: boolean;
	beneficiaries: boolean;
	documents: boolean;
	comPrefs: boolean;
	taxCenter: boolean;
	anchorInvestor: boolean;
	investorAuto: boolean;
	referralsSettings: boolean;
}

export const defaultFeatureToggles: SettingsMenuFeatureToggles = {
	personalInfo: false,
	security: false,
	bankAccounts: false,
	fundsXfer: false,
	fundsXferSchedule: false,
	autoInvesting: false,
	beneficiaries: false,
	documents: false,
	comPrefs: false,
	taxCenter: false,
	anchorInvestor: false,
	investorAuto: false,
	referralsSettings: false,
};

export default Vue.extend({
	name: 'SettingsMenu',
	props: {
		iwaHost: {
			type: String,
			required: true,
		} as PropOptions<string>,
		railsHost: {
			type: String,
			required: true,
		} as PropOptions<string>,
		isIwa: {
			type: Boolean,
			default: () => false,
		} as PropOptions<boolean>,
		accountType: {
			type: String,
			required: true,
		} as PropOptions<string>,
		currentUrl: {
			type: String,
			required: true,
		} as PropOptions<string>,
		user: {
			type: Object,
			required: true,
		},
		featureToggles: {
			type: Object,
			default: () => defaultFeatureToggles,
		} as PropOptions<SettingsMenuFeatureToggles>,
	},
	data() {
		return {
			SUPPORT_PHONE_NUMBER,
			SUPPORT_EMAIL_ADDRESS,
			SUPPORT_URL,
			itemSize: 'medium',
			formattedSupportPhone: formatPhone(SUPPORT_PHONE_NUMBER),
		};
	},
	computed: {
		accountTypeLabel(): string {
			if (this.isInvestorAccount) {
				return 'Investor Account';
			} else if (this.isIraAccount) {
				return 'IRA Account';
			} else {
				return '';
			}
		},
		isInvestorAccount(): boolean {
			return this.accountType === 'INVESTOR';
		},
		isIraAccount(): boolean {
			return this.accountType === 'INVESTOR_IRA';
		},
		canSetReferralRewardsAccount(): boolean {
			const accountTypes = this.user.myAccounts.map((account: any) => account.type);
			return ['INVESTOR', 'INVESTOR_AUTO'].every((type) => accountTypes.includes(type));
		},
		menuItems(): SettingMenuItem[] {
			return getSettings(this.featureToggles, this.accountType, this.canSetReferralRewardsAccount);
		},
	},
	methods: {
		getMenuItemHref(item: SettingMenuItem): string {
			if (item.enableIwa && item.iwaPath) {
				return getDomainUrl(this.iwaHost, item.iwaPath);
			} else if (item.railsPath) {
				return getDomainUrl(this.railsHost, item.railsPath);
			}
			return '';
		},
		currentPathIsInSubItems(subItems: SettingMenuItem[]): boolean {
			return subItems.some((item:SettingMenuItem) => {
				return this.getMenuItemHref(item).includes(this.$route.path) && this.$route.path !== '/settings/';
			});
		},
	},
});
