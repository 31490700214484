
import Vue from 'vue';

import SettingsMenu from '@/components/settings-menu/SettingsMenu.component.vue';

export default Vue.extend({
	name: 'LayoutTest',
	components: {
		SettingsMenu,
	},
	data() {
		return {
			currentUrl: window.location.href,
			toggles: {
				personalInfo: true,
				security: true,
				bankAccounts: true,
				fundsXfer: true,
				autoInvesting: true,
				beneficiaries: true,
				documents: true,
				comPrefs: true,
				taxCenter: true,
			},
			user: {},
		};
	},
});
