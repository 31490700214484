
import Vue, { PropOptions } from 'vue'; // eslint-disable-line
export default Vue.extend({
	name: 'Parallax',
	props: {
		scroll: {
			type: Boolean,
			default: true,
		} as PropOptions<boolean>,
		scrollPower: {
			type: Number,
			default: 1,
		} as PropOptions<number>,
		mouse: {
			type: Boolean,
			default: true,
		} as PropOptions<boolean>,
		mousePower: {
			type: Number,
			default: 1,
		} as PropOptions<number>,
	},
	data() {
		return {
			scrollY: 0,
			mouseX: 0,
			mouseY: 0,
		};
	},
	computed: {
		style(): Object {
			const { scrollPower, scrollY, mousePower, mouseX, mouseY } = this;
			return {
				transform: `translate3d(
                      ${ ((mouseX * 5) * mousePower) }px,
                      ${ ((scrollY / 7) * scrollPower) + ((mouseY * 5) * mousePower) }px,
                      0
                    )`,
				transition: 'transform 5s cubic-bezier(0, 0, .5, 1)',
			};
		},
	},
	mounted() {
		const { scroll, mouse, handleScroll, getMouseCoordinates } = this;
		if (scroll) document.addEventListener('scroll', handleScroll);
		if (mouse) document.addEventListener('mousemove', getMouseCoordinates);
	},
	destroyed() {
		const { scroll, mouse, handleScroll, getMouseCoordinates } = this;
		if (scroll) document.removeEventListener('scroll', handleScroll);
		if (mouse) document.removeEventListener('mousemove', getMouseCoordinates);
	},
	methods: {
		handleScroll() {
			window.requestAnimationFrame(() => {
				this.scrollY = window.scrollY;
			});
		},
		getMouseCoordinates(e:any) {
			window.requestAnimationFrame(() => {
				const widthMultiplier = 2;
				const heightMultiplier = 4;
				const innerWidth = window.innerWidth / widthMultiplier;
				const innerHeight = window.innerHeight / heightMultiplier;
				const x = ((((e.pageX - innerWidth) / (innerWidth)) * 10));
				const y = ((((e.pageY - innerHeight) / (innerHeight)) * heightMultiplier));

				this.mouseX = x;
				this.mouseY = y;
			});
		},
	},
});
