
import { useVuelidate } from '@vuelidate/core';
import {defineComponent, reactive, computed, PropOptions} from 'vue';// eslint-disable-line

import SimpleStrengthMeter from './SimpleStrengthMeter.vue';
import ValidationIndicator from './ValidationIndicator.vue';

import { passwordMinLength, passwordValidators } from '@/utils';

export default defineComponent({
	name: 'PasswordStrength',
	components: {
		ValidationIndicator,
		SimpleStrengthMeter,
	},
	props: {
		password: {
			type: String,
			required: true,
		} as PropOptions<string>,
		muteErrors: {
			type: Boolean,
			default: () => false,
		} as PropOptions<boolean>,
	},
	setup(props) {
		const rules = {
			password: passwordValidators,
		};
		const v$ = useVuelidate(rules, props);
		return { props, v$ };
	},
	data() {
		return {
			passwordMinLength,
		};
	},
	computed: {
		// This is a simple (shitty) replacement for zxcvbn. Ultimately that library wasn't worth the weight.
		simpleScore(): Number {
			let count = 0;

			if (!this.v$.password.required.$invalid && !this.v$.password.minLength.$invalid) {
				count++;
			}

			if (!this.v$.password.containsLowercase.$invalid) {
				count++;
			}

			if (!this.v$.password.containsUppercase.$invalid) {
				count += 2;
			}

			if (!this.v$.password.containsNumber.$invalid) {
				count += 2;
			}

			if (!this.v$.password.containsSymbol.$invalid) {
				count += 3;
			}

			// Bonus points for longer passwords
			if (this.password && this.password.length >= 14) {
				count++;
			}

			return count / 10;
		},
	},
});
