
import Vue from 'vue';

export default Vue.extend({
	name: 'FatCard',
	props: {
		title: {
			type: String,
			default: () => '',
		},
		iconSrc: {
			type: String,
			default: () => '',
		},
		iconWidth: {
			type: String,
			default: () => '',
		},
		iconHeight: {
			type: String,
			default: () => '',
		},
		centerTitle: {
			type: Boolean,
			default: () => false,
		},
		smallTitle: {
			type: Boolean,
			default: () => false,
		},
	},
	computed: {
		hasBack():boolean {
			return !!this.$slots.back || !!this.$scopedSlots.back;
		},
		titleClass(): string[] {
			const classes = [];

			classes.push(this.smallTitle ? 'is-4' : 'is-3');

			if (this.centerTitle) {
				classes.push('has-text-centered');
			}

			return classes;
		},
	},
});
