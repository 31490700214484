
import Vue, { PropOptions } from 'vue'; // eslint-disable-line
import { StringMap } from '../types'; // eslint-disable-line

export default Vue.extend({
	name: 'ImageHero',
	props: {
		imageUrl: {
			type: String,
			default: () => '',
		} as PropOptions<string>,
		position: {
			type: String,
			default: () => '',
		} as PropOptions<string>,
		type: {
			type: String,
			default: () => 'is-dark',
		} as PropOptions<string>,
		fullheight: {
			type: Boolean,
			default: () => false,
		} as PropOptions<boolean>,
	},
	computed: {
		heroStyle() {
			const style: StringMap<string> = {};

			if (this.imageUrl) {
				style.backgroundImage = `url('${ this.imageUrl }')`;
			}

			if (this.position) {
				style.backgroundPosition = this.position;
			}

			return style;
		},
	},
});
