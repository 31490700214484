
import { maska } from 'maska';
import Vue from 'vue';
import { VueTelInput } from 'vue-tel-input';

import { BANNED_COUNTRY_CODES } from '@/constants';

interface VueTelInputValidationEvent {
	valid: boolean;
}

export default Vue.extend({
	name: 'TelephoneField',
	components: {
		VueTelInput,
	},
	directives: { maska },
	props: {
		// This powers the v-model. We expect it to be a phone number
		value: {
			type: String,
			required: true,
		},
		required: {
			type: Boolean,
			required: false,
			default: true,
		},
		parentValid: {
			type: Boolean,
			required: false,
			default: true,
		},
		showSublabel: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
	data() {
		return {
			blurred: false,
			phoneNumber: this.value,
			BANNED_COUNTRY_CODES,
			phoneValid: true,
			sublabel: this.$props.required ? '(Required)' : '(Optional)',
		};
	},
	computed: {
		computedSublabel(): string {
			return this.showSublabel ? this.sublabel : '';
		},
		phoneType(): string {
			return (!this.phoneValid && this.blurred) || !this.parentValid
				? 'danger'
				: '';
		},
		phoneMessage(): string {
			if (!this.phoneValid && this.blurred) {
				return 'Phone number format = 000-000-0000';
			} else if (!this.parentValid) {
				return 'Required';
			} else {
				return '';
			}
		},
		tmpValue: {
			get(): string {
				return this.value;
			},
			set(value: string) {
				this.$emit('input', value);
			},
		},
	},
	methods: {
		handlePhoneValidation({ valid }: VueTelInputValidationEvent): void {
			const val = this.tmpValue;

			// Consider this field valid if it's empty but not required.
			if (!this.required && !val) {
				this.$emit('validate', true);
			}

			this.phoneValid = valid;
			this.$emit('validate', this.phoneValid);
		},
	},
});
