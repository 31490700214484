
import Vue from 'vue';

import { namedRoutes } from './router';

export default Vue.extend({
	name: 'App',
	data() {
		return {
			namedRoutes,
		};
	},
});
