
import Vue from 'vue';

export default Vue.extend({
	name: 'ComponentLibrary',
	data() {
		return {
			testPassword: '',
			isChecked: false,
			radioValue: 'first',
			phoneNumber: '',
			phoneValid: null as null | boolean,
		};
	},
	methods: {
		throwSnackbar(type: string) {
			this.$buefy.snackbar.open({
				message: 'Debris locks symbol dealing doors after disaster, inches running?',
				type,
				actionText: 'X',
			});
		},
		handlePhoneSubmit() {
			this.phoneValid = false;
		},
	},
});
