import { BuefyConfigExtended } from '@/types/buefy.types';

export const buefyConfig: BuefyConfigExtended = {
	defaultIconPack: 'fas',
	defaultToastPosition: 'is-bottom-right',
	defaultToastDuration: 2000,
	defaultIconComponent: 'font-awesome-icon',
	defaultStatusIcon: false,
	defaultSnackbarPosition: 'is-top-right',
	defaultSnackbarDuration: 5000,
};
