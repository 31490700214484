import { chunk, orderBy } from 'lodash';
import { LoremIpsum } from 'lorem-ipsum';

export interface TestData {
	id: number,
	foo: string,
	bar: string,
	fiz: string,
}

export interface MockQueryParams {
	page: number,
	perPage: number,
	sortField: string,
	sortOrder: string,
}

export interface MockQueryResponse {
	count: number,
	results: TestData[],
}

export const defaultParams: MockQueryParams = {
	page: 1,
	perPage: 10,
	sortField: 'id',
	sortOrder: 'asc',
};

export function mockQuery(pool: TestData[], params: MockQueryParams): Promise<MockQueryResponse> {
	return new Promise((resolve, reject) => {
		const sorted = orderBy([...pool], [params.sortField], [params.sortOrder as 'asc'|'desc']);
		const chunked = chunk(sorted, params.perPage);
		// Elsewhere the page is 1-indexed
		const zeroPage = params.page - 1;

		if (chunked[zeroPage]) {
			setTimeout(() => {
				resolve({
					results: chunked[zeroPage],
					count: pool.length,
				});
			}, 500);
		} else {
			reject(new Error(`Page ${ zeroPage } does not exist!`));
		}
	});
}

export function generateMockDataPool(size: number): TestData[] {
	const arr = new Array(size);
	const lorem = new LoremIpsum();

	return arr.fill(null).map((value, i) => {
		return {
			id: i,
			foo: lorem.generateWords(3).toLowerCase(),
			bar: lorem.generateWords(3).toLowerCase(),
			fiz: lorem.generateWords(3).toLowerCase(),
		};
	});
}
