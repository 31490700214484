<template>
	<transition name="fade">
		<div
			v-show="isActive"
			class="loading-overlay is-active"
			:class="{ 'is-full-page': displayInFullPage }"
		>
			<div class="loading-background" />
			<slot>
				<div class="loading-icon" />
			</slot>
		</div>
	</transition>
</template>

<script>

export default {
	name: 'GfLoading',
	props: {
		active: Boolean,
		isFullPage: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			isActive: this.active || false,
			displayInFullPage: this.isFullPage,
		};
	},
	watch: {
		active(value) {
			this.isActive = value;
		},
		isFullPage(value) {
			this.displayInFullPage = value;
		},
	},
};
</script>

<style lang="scss" scoped>
@import './../scss/imports';

.loading-overlay {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	z-index: $blur-z;

	.loading-background {
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		background: rgb(255 255 255 / 50%);

		.loading-icon {
			position: relative;
		}

		.loading-icon::after {
			animation: spinAround 500ms infinite linear;
			border-radius: 9999px;
			border-right-color: transparent;
			border-top-color: transparent;
			content: '';
			display: block;
			position: absolute;
			top: calc(50% - 1.5em);
			left: calc(50% - 1.5em);
			width: 3em;
			height: 3em;
			border-width: .25em;
		}
	}
}

.is-full-page {
	position: fixed;
	z-index: $blur-z;
}
</style>
