import { render, staticRenderFns } from "./BasicLayout.vue?vue&type=template&id=3c3ea189&"
import script from "./BasicLayout.ts?vue&type=script&lang=ts&"
export * from "./BasicLayout.ts?vue&type=script&lang=ts&"
import style0 from "./BasicLayout.vue?vue&type=style&index=0&id=3c3ea189&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports