
import Vue from 'vue';

export default Vue.extend({
	name: 'InfoBlock',
	props: {
		type: {
			type: String,
			default: () => '',
		},
	},
});
