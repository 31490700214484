import Vue from 'vue';

import { defaultParams, generateMockDataPool, mockQuery, MockQueryParams, TestData } from '@/utils/mock-table-data';

export default Vue.extend({
	name: 'TableLayout',
	data() {
		return {
			poolSize: 106,
			perPage: defaultParams.perPage,
			isLoading: true,
			tableColumns: [
				{
					field: 'id',
					label: '#',
					sortable: true,
				},
				{
					field: 'foo',
					label: 'Foo',
					sortable: true,
				},
				{
					field: 'bar',
					label: 'Bar',
					sortable: true,
				},
				{
					field: 'fiz',
					label: 'Fiz',
					sortable: true,
				},
			],
			backendResults: [] as TestData[],
			backendTotal: 0,
			backendPage: defaultParams.page,
			backendSortField: defaultParams.sortField,
			backendSortOrder: defaultParams.sortOrder,
			currentPage: 1,
		};
	},
	computed: {
		dataPool(): TestData[] {
			return generateMockDataPool(this.poolSize);
		},
	},
	mounted() {
		this.getMockData();
	},
	methods: {
		getMockData(): Promise<void> {
			return new Promise((resolve, reject) => {
				this.isLoading = true;

				const params:MockQueryParams = {
					page: this.backendPage,
					perPage: this.perPage,
					sortOrder: this.backendSortOrder,
					sortField: this.backendSortField,
				};

				mockQuery(this.dataPool, params)
					.then(({ count, results }) => {
						this.backendResults = results;
						this.backendTotal = count;
					})
					.catch((e) => {
						console.error(e);
					})
					.finally(() => {
						this.isLoading = false;
						resolve();
					});
			});
		},
		handlePageChange(page: number): void {
			this.backendPage = page;
			this.getMockData();
		},
		handleSortChange(field: 'id'|'foo'|'bar'|'fiz', order: 'asc'|'desc') {
			this.backendSortField = field;
			this.backendSortOrder = order;
			this.getMockData();
		},
	},
});
