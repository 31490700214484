import { LD_ACCOUNT_TYPES } from '@gffinance/conduit';
import Vue, { PropOptions } from 'vue';

import { AccountsMenuConfig, LabeledUrl } from './../../types';
import { getAccountLabel, userHasAccountOfType } from './../../utils/lending';

export default Vue.extend({
	name: 'AccountsMenu',
	props: {
		activeAccount: {
			type: Object,
			required: true,
		},
		user: {
			type: Object,
			required: true,
		},
		config: {
			type: Object,
			required: true,
		} as PropOptions<AccountsMenuConfig>,
		allAccountsCreated: {
			type: Boolean,
			required: false,
			default: () => false,
		} as PropOptions<boolean>,
	},
	computed: {
		displayedAccounts(): any[] {
			return this.user.accounts.filter((account: any) => {
				return [
					LD_ACCOUNT_TYPES.IRA,
					LD_ACCOUNT_TYPES.INVESTOR,
					LD_ACCOUNT_TYPES.BROKER,
					LD_ACCOUNT_TYPES.BORROWER,
				].includes(account.type);
			});
		},
		otherAccounts(): LabeledUrl[] {
			const accounts = [];

			if (!userHasAccountOfType(this.user, LD_ACCOUNT_TYPES.INVESTOR)) {
				accounts.push({
					label: 'Investor',
					url: this.config.investorMarketingUrl,
				});
			}

			if (!userHasAccountOfType(this.user, LD_ACCOUNT_TYPES.IRA)) {
				accounts.push({
					label: 'IRA',
					url: this.config.iraMarketingUrl,
				});
			}

			if (!userHasAccountOfType(this.user, LD_ACCOUNT_TYPES.BORROWER)) {
				accounts.push({
					label: 'Borrower',
					url: this.config.borrowerMarketingUrl,
				});
			}

			if (!userHasAccountOfType(this.user, LD_ACCOUNT_TYPES.BROKER)) {
				accounts.push({
					label: 'Broker',
					url: this.config.brokerMarketingUrl,
				});
			}

			return accounts;
		},
	},
	methods: {
		getAccountLabel,
		handleSwitch(account: any): void {
			if (!this.isAccountActive(account)) {
				this.$emit('switch', account);
			}
		},
		isAccountActive(account: any): boolean {
			return account.id === this.activeAccount.id;
		},
	},
});
