import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import { StringMap } from '../../src/types';
import ComponentLibrary from '../views/ComponentLibrary.vue';
import Home from '../views/Home.vue';
import Layout from '../views/Layout.vue';
import LendingLayout from '../views/lending-layout/LendingLayout.component.vue';
import RouterTable from '../views/RouterTable/RouterTable.vue';
import StyleGuide from '../views/style-guide.vue';
import TableLayout from '../views/TableLayout/TableLayout.vue';

Vue.use(VueRouter);

export const namedRoutes: StringMap<RouteConfig> = {
	Home: {
		path: '/',
		name: 'Home',
		component: Home,
	},
	ComponentLibrary: {
		path: '/componentLibrary',
		name: 'ComponentLibrary',
		component: ComponentLibrary,
	},
	StyleGuide: {
		path: '/',
		name: 'StyleGuide',
		component: StyleGuide,
	},
	Layout: {
		path: '/layout',
		name: 'Layout',
		component: Layout,
	},
	TableLayout: {
		path: '/table',
		name: 'TableLayout',
		component: TableLayout,
	},
	RouterTable: {
		path: '/router-table',
		name: 'RouterTable',
		component: RouterTable,
	},
	LendingLayout: {
		path: '/lending-layout',
		name: 'LendingLayout',
		component: LendingLayout,
	},
};

const routes: Array<RouteConfig> = [
	namedRoutes.ComponentLibrary,
	namedRoutes.Home,
	namedRoutes.Layout,
	namedRoutes.LendingLayout,
	namedRoutes.RouterTable,
	namedRoutes.StyleGuide,
	namedRoutes.TableLayout,
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
