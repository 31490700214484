import {
	library,
	dom,
} from '@fortawesome/fontawesome-svg-core';
import {
	faFacebook,
	faInstagram,
	faLinkedin,
	faTwitter,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
	faAngleDown,
	faAngleLeft,
	faAngleRight,
	faArrowDown,
	faArrowUp,
	faBell,
	faCalendarAlt,
	faCaretDown,
	faCaretUp,
	faCheck,
	faCheckCircle,
	faChevronLeft,
	faChevronRight,
	faCircle,
	faCogs,
	faExclamationCircle,
	faExclamationTriangle,
	faEye,
	faEyeSlash,
	faFile,
	faFileAlt,
	faFileInvoiceDollar,
	faFileSignature,
	faHandHoldingUsd,
	faLink,
	faPiggyBank,
	faQuestion,
	faSearch,
	faSyncAlt,
	faUniversity,
	faUser,
	faUserCircle,
	faUserCog,
	faUserFriends,
	faUserShield,
	faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import {
	faBars,
	faInfoCircle,
	faTimes,
	faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons';
import {
	faSackDollar,
} from '@fortawesome/pro-solid-svg-icons';

import {
	faBorrowerAccounts,
	faBorrowerDashboard,
	faBorrowerEntity,
	faBorrowerProfile,
	faBorrowerUser,
	faFileJpg,
	faFilePng,
	faGuarantor,
	faMoneyBag,
	faMoneyHand,
	faPricingTool,
	faPropertyPin,
	faRecurringTransfers,
} from '@/custom-icons';

library.add(faRecurringTransfers,
	faFileJpg,
	faFilePng,
	faMoneyBag,
);
library.add(
	faAngleDown,
	faAngleLeft,
	faAngleRight,
	faArrowDown,
	faArrowUp,
	faBars,
	faBell,
	faCalendarAlt,
	faCaretDown,
	faCaretUp,
	faCheck,
	faCheckCircle,
	faChevronLeft,
	faChevronRight,
	faCircle,
	faCogs,
	faExclamationCircle,
	faExclamationTriangle,
	faEye,
	faEyeSlash,
	faFacebook,
	faFile,
	faFileAlt,
	faFileInvoiceDollar,
	faFileSignature,
	faHandHoldingUsd,
	faInfoCircle,
	faInstagram,
	faLink,
	faLinkedin,
	faPiggyBank,
	faQuestion,
	faSackDollar,
	faSearch,
	faSyncAlt,
	faTimes,
	faTrashAlt,
	faTwitter,
	faUniversity,
	faUser,
	faUserCircle,
	faUserCog,
	faUserFriends,
	faUserShield,
	faUserTie,
	faYoutube,
);

library.add(
	faBorrowerAccounts,
	faBorrowerDashboard,
	faBorrowerEntity,
	faBorrowerProfile,
	faBorrowerUser,
	faGuarantor,
	faMoneyHand,
	faPricingTool,
	faPropertyPin,
	faRecurringTransfers,
);

// Not sure what this is for...
// dom.watch();
