import Dinero from 'dinero.js';
import parsePhoneNumber from 'libphonenumber-js';

import {
	defaultFeatureToggles as featureToggles,
	SettingMenuItem,
	SettingsMenuFeatureToggles,
} from './../components/settings-menu/SettingsMenu.component';
import {
	IWA_PERSONAL_INFO_PATH,
	IWA_SECURITY_SETTINGS_PATH,
	IWA_BENEFICIARIES_PATH,
	IWA_DOCUMENTS_PATH,
	IWA_COM_PREFS_PATH,
	IWA_TAX_FORMS_PATH,
	IWA_TAX_REPORTING_INFO_PATH,
	IWA_BANK_ACCOUNT_LIST_PATH,
	RAILS_PERSONAL_INFO_PATH,
	RAILS_SECURITY_PATH,
	IWA_AUTO_INVESTING_PATH,
	RAILS_AUTO_INVESTING_PATH,
	RAILS_BENEFICIARIES_PATH,
	RAILS_DOCUMENTS_PATH,
	RAILS_COM_PREFS_PATH,
	RAILS_TAX_CENTER_PATH,
	RAILS_BANK_ACCOUNTS_PATH,
	RAILS_FUNDS_XFER_PATH,
	IWA_ANCHOR_INVESTOR_PATH,
	RAILS_ANCHOR_INVESTOR_PATH,
	IWA_RECURRING_TRANSFERS_PATH,
	IWA_FUNDS_XFER_LIST_PATH,
	IWA_INVESTOR_AUTO_SETTINGS_PATH,
	IWA_REFERRALS_SETTINGS_PATH,
} from './../constants';

interface options {
	first?: Boolean;
};

const iwaHost = 'app.local.gandalf-struts.com:8083';
const railsHost = 'www.edge.gandalf-struts.com';
const accountType = 'INVESTOR';

export function getDomainUrl(domain: string, url: string): string {
	return `https://${ domain }${ url }`;
}

export function formatCents(cents: number): string {
	return Dinero({
		amount: cents,
		currency: 'USD',
	})
		.toFormat();
}

export function formatPhone(rawPhone: string): string | null {
	const phone = parsePhoneNumber(rawPhone, 'US');
	return phone ? phone.formatNational() : null;
}

export function getSettings(featureToggles: SettingsMenuFeatureToggles, accountType: String, canSetReferralRewardsAccount: Boolean): SettingMenuItem[] {
	const coreInvestorItems = [
		{
			icon: 'university',
			label: 'Bank Accounts',
			enableIwa: featureToggles.bankAccounts,
			iwaPath: IWA_BANK_ACCOUNT_LIST_PATH,
			railsPath: RAILS_BANK_ACCOUNTS_PATH,
			text: 'Add a bank account to begin investing.',
			cta: 'Add Funding Source',
			button: 'Bank Accounts',
		},
		{
			icon: 'piggy-bank',
			label: 'Funds Transfers',
			enableIwa: true,
			iwaPath: IWA_FUNDS_XFER_LIST_PATH,
			railsPath: '',
			text: 'Transfer funds to or from your Groundfloor account.',
			cta: 'Setup Transfers',
			button: 'Funds Transfers',
		},
		{
			icon: 'sync-alt',
			label: 'Recurring Transfer Schedules',
			enableIwa: featureToggles.fundsXferSchedule,
			iwaPath: IWA_RECURRING_TRANSFERS_PATH,
			railsPath: RAILS_FUNDS_XFER_PATH,
			text: 'Setup a re-occurring transfer schedule to make sure your account is well funded.',
			cta: 'Edit Transfers',
			button: 'Transfer Schedules',
		},
	];

	const items:SettingMenuItem[] = [
		{
			icon: 'user',
			label: 'My Profile',
			enableIwa: featureToggles.personalInfo,
			iwaPath: IWA_PERSONAL_INFO_PATH,
			railsPath: RAILS_PERSONAL_INFO_PATH,
			text: 'Update your email, name, address, and other personal information here.',
			cta: 'Edit My Profile',
			button: 'My Profile',
		},
		{
			icon: 'user-shield',
			label: 'Security',
			enableIwa: featureToggles.security,
			iwaPath: IWA_SECURITY_SETTINGS_PATH,
			railsPath: RAILS_SECURITY_PATH,
			text: 'Manage your security settings to help prevent unauthorized access to your account.',
			cta: 'Edit Settings',
			button: 'Security',
		},
		{
			icon: 'user-friends',
			label: 'Beneficiaries',
			enableIwa: featureToggles.beneficiaries,
			iwaPath: IWA_BENEFICIARIES_PATH,
			railsPath: RAILS_BENEFICIARIES_PATH,
			text: 'Setup beneficiary information for your investor account.',
			cta: 'Edit Beneficiaries',
			button: 'Beneficiaries',
		},
		{
			icon: 'file-signature',
			label: 'Documents',
			enableIwa: featureToggles.documents,
			iwaPath: IWA_DOCUMENTS_PATH,
			railsPath: RAILS_DOCUMENTS_PATH,
			text: 'Signed documents, investor statements, and other related documents can be viewed here.',
			cta: 'Show Documents',
			button: 'Documents',
		},
		{
			icon: 'bell',
			label: 'Communication Preferences',
			enableIwa: featureToggles.comPrefs,
			iwaPath: IWA_COM_PREFS_PATH,
			railsPath: RAILS_COM_PREFS_PATH,
			text: 'Email, in app notifications, and other communication preferences can be managed here',
			cta: 'Manage Preferences',
			button: 'Comm. Preferences',
		},
		{
			icon: 'file-invoice-dollar',
			label: 'Tax Center',
			enableIwa: featureToggles.taxCenter,
			iwaPath: undefined,
			railsPath: RAILS_TAX_CENTER_PATH,
			text: 'View important tax information including interest earned and download your tax documents',
			cta: 'Edit Tax Info',
			button: 'Tax Center',
			noteMessage: 'Be sure to verify your personal information for tax reporting purposes.',
		},
		{
			icon: 'hand-holding-usd',
			label: 'Anchor Investor Program',
			enableIwa: featureToggles.anchorInvestor,
			iwaPath: IWA_ANCHOR_INVESTOR_PATH,
			railsPath: RAILS_ANCHOR_INVESTOR_PATH,
			text: 'Accredited Investors get exclusive access to new features and special investment opportunities. Some restrictions apply.',
			cta: 'Edit Accreditation',
			button: 'Investor Program',
		},
	];

	if (accountType === 'INVESTOR_AUTO') {
		items.splice(2, 0,
			...coreInvestorItems,
			{
				icon: 'user-cog',
				label: 'Auto Investor Account Settings',
				enableIwa: true,
				iwaPath: IWA_INVESTOR_AUTO_SETTINGS_PATH,
				railsPath: '',
				text: 'Set automatic investing status and cash target',
				cta: 'Investor Auto Status',
				button: 'Auto Investor Account Settings',
			},
		);
	}

	if (accountType === 'INVESTOR' || accountType === 'INVESTOR_IRA') {
		items.splice(2, 0,
			...coreInvestorItems,
			{
				icon: 'cogs',
				label: 'Auto Investing',
				enableIwa: featureToggles.autoInvesting,
				iwaPath: IWA_AUTO_INVESTING_PATH,
				railsPath: RAILS_AUTO_INVESTING_PATH,
				text: 'Setup automatic investing to make sure you don\'t miss out on a great opportunity.',
				cta: 'Edit Auto Investments',
				button: 'Auto Investing',
			},
		);
	}

	if ((accountType === 'INVESTOR' || accountType === 'INVESTOR_AUTO') && canSetReferralRewardsAccount) {
		items.splice(5, 0,
			{
				icon: 'handshake',
				label: 'Referral Rewards',
				enableIwa: true,
				iwaPath: IWA_REFERRALS_SETTINGS_PATH,
				railsPath: '',
				text: 'Select an account to receive referral rewards.',
				cta: 'Referral Rewards',
				button: 'Referral Rewards',
			},
		);
	}

	return items;
}
