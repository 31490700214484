
import Vue from 'vue';

export default Vue.extend({
	data() {
		return {
			id: Math.floor(100000 + Math.random() * 900000).toString(),
		};
	},
});

