<template>
	<label
		ref="label"
		:class="rootClasses"
		class="checkbox"
		:disabled="disabled"
		@click.stop="focus"
		@keydown.prevent.enter="$refs.label.click()"
	>
		<input
			ref="input"
			v-model="computedValue"
			:indeterminate.prop="indeterminate"
			type="checkbox"
			:class="checkClasses"
			:disabled="disabled"
			:required="required"
			:name="name"
			:autocomplete="autocomplete"
			:value="nativeValue"
			:true-value="trueValue"
			:false-value="falseValue"
			:aria-labelledby="ariaLabelledby"
			@click.stop
		>
		<span class="check mr-3" />
		<span
			:id="ariaLabelledby"
			:class="labelClasses"
		>
			<slot />
		</span>
	</label>
</template>

<script>
import BaseComponentMixin from '@oruga-ui/oruga/src/utils/BaseComponentMixin';
import CheckRadioMixin from '@oruga-ui/oruga/src/utils/CheckRadioMixin';

/**
 * This is an almost exact copy of Buefy's checkbox, which works quite differently than Oruga's
 *
 * Select a single or grouped options
 * @displayName Checkbox
 * @example ./examples/Checkbox.md
 * @style _checkbox.scss
 */
export default {
	name: 'GfCheckbox',
	mixins: [BaseComponentMixin, CheckRadioMixin],
	configField: 'checkbox',
	props: {
		/**
		 * Same as native indeterminate
		 */
		indeterminate: {
			type: Boolean,
			default: false,
		},
		/**
		 * Overrides the returned value when it's checked
		 */
		trueValue: {
			type: [String, Number, Boolean],
			default: true,
		},
		/**
		 * Overrides the returned value when it's not checked
		 */
		falseValue: {
			type: [String, Number, Boolean],
			default: false,
		},
		/** Accessibility label to establish relationship between the checkbox and control label */
		ariaLabelledby: {
			type: String,
			default: '',
		},
		/* Same as native autocomplete */
		autocomplete: {
			type: String,
			default: '',
		},
		rootClass: {
			type: [String, Function, Array],
			default: '',
		},
		disabledClass: {
			type: [String, Function, Array],
			default: '',
		},
		checkClass: {
			type: [String, Function, Array],
			default: '',
		},
		checkedClass: {
			type: [String, Function, Array],
			default: '',
		},
		checkCheckedClass: {
			type: [String, Function, Array],
			default: '',
		},
		checkIndeterminateClass: {
			type: [String, Function, Array],
			default: '',
		},
		labelClass: {
			type: [String, Function, Array],
			default: '',
		},
		sizeClass: {
			type: [String, Function, Array],
			default: '',
		},
		variantClass: {
			type: [String, Function, Array],
			default: '',
		},
	},
	computed: {
		isChecked() {
			return this.computedValue === this.trueValue
				|| (Array.isArray(this.computedValue) && this.computedValue.indexOf(this.nativeValue) !== -1);
		},
		rootClasses() {
			return [
				this.computedClass('rootClass', 'b-checkbox'),
				{ [this.computedClass('checkedClass', 'b-checkbox--checked')]: this.isChecked },
				{ [this.computedClass('sizeClass', 'b-checkbox--', this.size)]: this.size },
				{ [this.computedClass('disabledClass', 'b-checkbox--disabled')]: this.disabled },
				{ [this.computedClass('variantClass', 'b-checkbox--', this.variant)]: this.variant },
			];
		},
		checkClasses() {
			return [
				this.computedClass('checkClass', 'b-checkbox__check'),
				{ [this.computedClass('checkCheckedClass', 'b-checkbox__check--checked')]: this.isChecked },
				{ [this.computedClass('checkIndeterminateClass', 'b-checkbox__check--indeterminate')]: this.isIndeterminate },
			];
		},
		labelClasses() {
			return [
				this.computedClass('labelClass', 'b-checkbox__label'),
			];
		},
	},
	watch: {
		indeterminate: {
			handler(val) {
				this.isIndeterminate = val;
			},
			immediate: true,
		},
	},
};
</script>
