
import Vue from 'vue';

/* eslint-disable no-unused-vars, @typescript-eslint/no-namespace */
declare namespace ReCaptchaV3 {
	interface Action {
		action: string;
	}

	interface Parameters {
		sitekey?: string | undefined;
		action?: Action | undefined;
		callback?(response: string): void;
	}

	interface ReCaptcha {
		execute(siteKey: string, action: Action): PromiseLike<string>;
		ready(callback: () => void): void;
	}
}
/* eslint-enable no-unused-vars, @typescript-eslint/no-namespace */

declare let grecaptcha: ReCaptchaV3.ReCaptcha & {
	enterprise: ReCaptchaV3.ReCaptcha;
};

let uniqueId = 0; // sets a unique ID for the component

export default Vue.extend({
	name: 'V3Recaptcha',
	props: {
		sitekey: {
			type: String,
			required: true,
		},

		action: {
			type: String,
			required: false,
			default: 'unspecified',
		},

		callback: {
			type: Function,
			required: true,
		},

		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},

		id: {
			type: String,
			required: false,
			default: undefined,
		},

		reference: {
			type: String,
			required: false,
			default: undefined,
		},

		type: {
			type: String,
			required: false,
			default: 'is-gradient',
		},

		nativeType: {
			type: String,
			required: false,
			default: 'submit',
		},

		size: {
			type: String,
			required: false,
			default: 'is-medium',
		},

		label: {
			type: String || undefined,
			required: false,
			default: undefined,
		},

		loading: {
			type: Boolean,
			required: false,
			default: false,
		},

		rounded: {
			type: Boolean,
			required: false,
			default: true,
		},

		outlined: {
			type: Boolean,
			required: false,
			default: false,
		},

		focused: {
			type: Boolean,
			required: false,
			default: false,
		},

		inverted: {
			type: Boolean,
			required: false,
			default: false,
		},

		hovered: {
			type: Boolean,
			required: false,
			default: false,
		},

		active: {
			type: Boolean,
			required: false,
			default: false,
		},

		selected: {
			type: Boolean,
			required: false,
			default: false,
		},

		expanded: {
			type: Boolean,
			required: false,
			default: false,
		},

		iconLeft: {
			type: String || undefined,
			required: false,
			default: undefined,
		},

		iconRight: {
			type: String || undefined,
			required: false,
			default: undefined,
		},

		iconPack: {
			type: String,
			required: false,
			default: 'fas',
		},

		tag: {
			type: String,
			required: false,
			default: 'button',
		},
	},

	data() {
		uniqueId += 1; // increments unique ID for each component instance
		return {
			recaptchaId: `v3-recaptcha-${ uniqueId }`,
		};
	},

	async mounted() {
		if (typeof grecaptcha === 'undefined') {
			await this.loadRecaptchaScript().catch(e => this.errorToast(e));
		}
		// because grecaptcha.execute provides no method of catching the errors it
		// generates, we must watch the window itself for errors once the script
		// has loaded in order to pass them along to our error toast component.
		// This is so far beyond ideal it hurts.
		window.addEventListener('error', e => this.errorToast(e.message));
	},

	beforeDestroy() {
		window.removeEventListener('error', e => this.errorToast(e.message));
	},

	methods: {
		async click() {
			/* *************************************************************
			 if somehow we get here and Recaptcha script is not loaded then
			 wait for it to load by calling the `loadRecaptchaScript` using
			 async/await.
			************************************************************** */
			if (typeof grecaptcha === 'undefined') {
				await this.loadRecaptchaScript().catch(e => this.errorToast(e));
			}
			// emitting event to the parent component so that it can show spinner
			// immediately instead of waiting until after the recaptcha is loaded
			// successfully and the token is returned to the parent component.
			// Parent component needs to handle the spinner display logic.
			this.$emit('spinner');
			this.executeRecaptchaScript();
		},
		async loadRecaptchaScript() {
			return new Promise<void>((resolve, reject) => {
				const script = document.createElement('script');
				script.src = 'https://www.google.com/recaptcha/api.js?render=' + this.sitekey;
				document.head.appendChild(script);
				script.onload = () => resolve();
				script.onerror = (e: String | Event) => reject(
					new Error('Error loading reCAPTCHA. Refresh the page to try again.'),
				);
			});
		},
		executeRecaptchaScript() {
			grecaptcha.ready(() => {
				try {
					grecaptcha.execute(this.sitekey, { action: this.action })
						.then(token => {
							this.callback(token);
						});
				} catch (e) {
					this.errorToast('Error loading reCAPTCHA. Refresh the page to try again.');
					this.$emit('recaptcha-error', e);
				}
			});
		},
		errorToast(error: string): void {
			this.$buefy.snackbar.open({
				message: error,
				type: 'is-danger',
				actionText: 'X',
			});
		},
	},
});
