<template>
	<label
		ref="label"
		class="b-radio radio"
		:class="[size, { 'is-disabled': disabled }]"
		:disabled="disabled"
		@click="focus"
		@keydown.prevent.enter="$refs.label.click()"
	>
		<input
			ref="input"
			v-model="computedValue"
			type="radio"
			:disabled="disabled"
			:required="required"
			:name="name"
			:value="nativeValue"
			@click.stop
		>
		<span
			class="check"
		/>
		<span class="control-label"><slot /></span>
	</label>
</template>

<script>
import BaseComponentMixin from '@oruga-ui/oruga/src/utils/BaseComponentMixin';
import CheckRadioMixin from '@oruga-ui/oruga/src/utils/CheckRadioMixin';

/**
 * This is an almost exact copy of Buefy's radio, which works quite differently than Oruga's
 *
 * Select an option from a set
 * @displayName Radio
 * @example ./examples/Radio.md
 * @style _radio.scss
 */
export default {
	name: 'GfRadio',
	mixins: [BaseComponentMixin, CheckRadioMixin],
	configField: 'radio',
	props: {
		rootClass: {
			type: [String, Function, Array],
			default: '',
		},
		disabledClass: {
			type: [String, Function, Array],
			default: '',
		},
		checkClass: {
			type: [String, Function, Array],
			default: '',
		},
		checkedClass: {
			type: [String, Function, Array],
			default: '',
		},
		checkCheckedClass: {
			type: [String, Function, Array],
			default: '',
		},
		checkIndeterminateClass: {
			type: [String, Function, Array],
			default: '',
		},
		labelClass: {
			type: [String, Function, Array],
			default: '',
		},
		sizeClass: {
			type: [String, Function, Array],
			default: '',
		},
		variantClass: {
			type: [String, Function, Array],
			default: '',
		},
	},
	computed: {
		isChecked() {
			return this.value === this.nativeValue;
		},
		rootClasses() {
			return [
				this.computedClass('rootClass', 'o-radio'),
				{ [this.computedClass('sizeClass', 'o-radio--', this.size)]: this.size },
				{ [this.computedClass('checkedClass', 'o-radio--checked')]: this.isChecked },
				{ [this.computedClass('disabledClass', 'o-radio--disabled')]: this.disabled },
				{ [this.computedClass('variantClass', 'o-radio--', this.variant)]: this.variant },
			];
		},
		checkClasses() {
			return [
				this.computedClass('checkClass', 'o-radio__check'),
				{ [this.computedClass('checkCheckedClass', 'o-radio__check--checked')]: this.isChecked },
			];
		},
		labelClasses() {
			return [
				this.computedClass('labelClass', 'o-radio__label'),
			];
		},
	},
};
</script>
