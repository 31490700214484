type Class = string | ((_: string, { props, computed }: any) => string | undefined) | undefined;
type ThPositionClass = ((position: string) => 'is-right' | 'is-centered' | undefined) | undefined
type TdPositionClass = ((position: string) => 'has-text-centered' | 'has-text-right' | undefined) | undefined
export enum IconPackTypes {
  Fa = 'fa',
  Fab = 'fab',
  FaBrands = 'fa-brands',
  Fad = 'fad',
  FaDuotone = 'fa-duotone',
  Fal = 'fal',
  FaLight = 'fa-light',
  Far = 'far',
  FaRegular = 'fa-regular',
  Fas = 'fas',
  FaSolid = 'fa-solid',
  Fat = 'fat',
  FaThin = 'fa-thin',
  Mdi = 'mdi',
}
export interface Field {
  override: boolean;
  rootClass: Class;
  labelClass: Class;
  labelSizeClass: Class;
  messageClass: Class;
  variantMessageClass: Class;
  addonsClass: Class;
  groupedClass: Class;
  groupMultilineClass: Class;
  horizontalClass: Class;
  labelHorizontalClass: Class;
  bodyHorizontalClass: Class;
  bodyClass: Class;
};
export interface Input {
  override: boolean;
  rootClass: Class;
  inputClass: Class;
  textareaClass: Class;
  roundedClass: Class;
  variantClass: Class;
  sizeClass: Class;
  expandedClass: Class;
  iconLeftClass: Class;
  iconRightClass: Class;
  counterClass: Class;
};
export interface Select {
  override: boolean;
  rootClass: Class;
  expandedClass: Class;
  iconLeftClass: Class;
  iconRightClass: Class;
  placeholderClass: Class;
};
export interface Icon {
  override: boolean;
  rootClass: Class;
  variantClass: Class;
  sizeClass: Class;
  clickableClass: Class;
  spinClass: Class;
};
export interface CheckboxOrRadio {
  override: boolean;
  rootClass: Class;
  disabledClass: Class;
  checkClass: Class;
  labelClass: Class;
  variantClass: Class;
  sizeClass: Class;
};
export interface Switch {
  override: boolean;
  rootClass: Class;
  checkClass: Class;
  labelClass: Class;
  sizeClass: Class;
  disabledClass: Class;
};
export interface Autocomplete {
  override: boolean;
  rootClass: Class;
  menuClass: Class;
  menuPositionClass: Class;
  itemClass: Class;
  itemHoverClass: Class;
  itemEmptyClasses: Class;
  itemGroupTitleClass: Class;
};
export interface Inputitems {
  override: boolean;
  rootClass: Class;
  containerClass: Class;
  itemClass: Class;
  closeClass: Class;
};
export interface Pagination {
  override: boolean;
  rootClass: Class;
  sizeClass: Class;
  simpleClass: Class;
  orderClass: Class;
  listClass: Class;
  linkClass: Class;
  linkCurrentClass: Class;
  linkDisabledClass: Class;
  nextBtnClass: Class;
  prevBtnClass: Class;
  infoClass: Class;
};
export interface Slider {
  override: boolean;
  rootClass: Class;
  disabledClass: Class;
  trackClass: Class;
  fillClass: Class;
  thumbWrapperClass: Class;
  sizeClass: Class;
  thumbClass: Class;
  tickLabelClass: Class;
  tickHiddenClass: Class;
  tickClass: Class;
};
export interface Tabs {
  override: boolean;
  itemTag: Class;
  rootClass: Class;
  contentClass: Class;
  multilineClass: Class;
  navTabsClass: Class;
  expandedClass: Class;
  verticalClass: Class;
  positionClass: Class;
  navSizeClass: Class;
  navPositionClass: Class;
  transitioningClass: Class;
  itemClass: Class;
  itemHeaderActiveClass: Class;
  itemHeaderDisabledClass: Class;
};
export interface Table {
  override: boolean;
  rootClass: Class;
  wrapperClass: Class;
  tableClass: Class;
  borderedClass: Class;
  stripedClass: Class;
  narrowedClass: Class;
  hoverableClass: Class;
  emptyClass: Class;
  detailedClass: Class;
  footerClass: Class;
  paginationWrapperClass: Class;
  scrollableClass: Class;
  trSelectedClass: Class;
  thSortableClass: Class;
  thCurrentSortClass: Class;
  thSortIconClass: Class;
  thUnselectableClass: Class;
  thStickyClass: Class;
  thCheckboxClass: Class;
  thDetailedClass: Class;
  tdDetailedChevronClass: Class;
  thPositionClass: ThPositionClass;
  tdPositionClass: TdPositionClass;
  mobileClass: Class;
  mobileSortClass: Class;
};
export interface Tooltip {
  override: boolean;
  rootClass: Class;
  contentClass: Class;
  triggerClass: Class;
  alwaysClass: Class;
  multilineClass: Class;
  variantClass: Class;
  orderClass: Class;
};
export interface Steps {
  override: boolean;
  rootClass: Class;
  stepsClass: Class;
  itemClass: Class;
  itemHeaderClass: Class;
  itemHeaderVariantClass: Class;
  itemHeaderActiveClass: Class;
  itemHeaderPreviousClass: Class;
  stepLinkClass: Class;
  stepLinkLabelClass: Class;
  stepLinkClickableClass: Class;
  stepMarkerClass: Class;
  stepNavigationClass: Class;
  stepContentClass: Class;
  verticalClass: Class;
  positionClass: Class;
  stepContentTransitioningClass: Class;
  sizeClass: Class;
  variantClass: Class;
};
export interface Button {
  override: boolean;
  rootClass: Class;
  sizeClass: Class;
  variantClass: Class;
  roundedClass: Class;
  expandedClass: Class;
  loadingClass: Class;
  outlinedClass: Class;
  invertedClass: Class;
  elementsWrapperClass: Class;
};
export interface Menu {
  override: boolean;
  rootClass: Class;
  listClass: Class;
  listLabelClass: Class;
};
export interface Skeleton {
  override: boolean;
  rootClass: Class;
  itemClass: Class;
  itemRoundedClass: Class;
};
export interface Notification {
  override: boolean;
  rootClass: Class;
  wrapperClass: Class;
  contentClass: Class;
  iconClass: Class;
  closeClass: Class;
  positionClass: Class;
  noticeClass: Class;
  noticePositionClass: Class;
};
export interface Dropdown {
  override: boolean;
  itemTag: Class;
  rootClass: Class;
  triggerClass: Class;
  menuClass: Class;
  disabledClass: Class;
  expandedClass: Class;
  inlineClass: Class;
  itemClass: Class;
  itemActiveClass: Class;
  itemDisabledClass: Class;
  mobileClass: Class;
  menuMobileOverlayClass: Class;
};
export interface Datepicker {
  override: boolean;
  rootClass: Class;
  headerClass: Class;
  footerClass: Class;
  boxClass: Class;
  tableClass: Class;
  tableHeadClass: Class;
  tableHeadCellClass: Class;
  headerButtonsClass: Class;
  prevBtnClass: Class;
  nextBtnClass: Class;
  listsClass: Class;
  tableBodyClass: Class;
  tableRowClass: Class;
  tableCellClass: Class;
  tableCellSelectableClass: Class;
  tableCellUnselectableClass: Class;
  tableCellTodayClass: Class;
  tableCellSelectedClass: Class;
  tableCellWithinHoveredClass: Class;
  tableCellFirstHoveredClass: Class;
  tableCellLastHoveredClass: Class;
  tableCellFirstSelectedClass: Class;
  tableCellLastSelectedClass: Class;
  tableCellWithinSelectedClass: Class;
  tableCellInvisibleClass: Class;
  tableCellNearbyClass: Class;
  tableCellEventsClass: Class;
  tableEventVariantClass: Class;
  tableEventsClass: Class;
  tableEventClass: Class;
};
export interface Modal {
  override: boolean;
  rootClass: Class;
  overlayClass: Class;
  contentClass: Class;
  closeClass: Class;
  fullScreenClass: Class;
};
export interface Sidebar {
  override: boolean;
  rootClass: Class;
  variantClass: Class;
  contentClass: Class;
  staticClass: Class;
  absoluteClass: Class;
  fixedClass: Class;
  expandOnHoverClass: Class;
  expandOnHoverFixedClass: Class;
  fullheightClass: Class;
  fullwidthClass: Class;
  mobileClass: Class;
  overlayClass: Class;
  reduceClass: Class;
  rightClass: Class;
};
export interface Loading {
  fullPageClass: Class;
  overlayClass: Class;
  iconClass: Class;
  rootClass: Class;
};
export interface SelectClasses {
  rootClass: Class;
};
export interface Timepicker {
  override: boolean;
  rootClass: Class;
  boxClass: Class;
  selectClasses: SelectClasses;
  separatorClass: Class;
  footerClass: Class;
  sizeClass: Class;
};
export interface Carousel {
  override: boolean;
  rootClass: Class;
  overlayClass: Class;
  sceneClass: Class;
  itemsClass: Class;
  itemsDraggingClass: Class;
  arrowIconClass: Class;
  arrowIconPrevClass: Class;
  arrowIconNextClass: Class;
  indicatorsClass: Class;
  indicatorClass: Class;
  indicatorsInsideClass: Class;
  indicatorsInsidePositionClass: Class;
  indicatorItemClass: Class;
  indicatorItemActiveClass: Class;
  indicatorItemStyleClass: Class;
  itemClass: Class;
  itemActiveClass: Class;
};
export interface Upload {
  override: boolean;
  rootClass: Class;
  draggableClass: Class;
  variantClass: Class;
  expandedClass: Class;
  disabledClass: Class;
  hoveredClass: Class;
};
export default interface OrugaConfig {
  field: Field;
  input: Input;
  select: Select;
  icon: Icon;
  iconComponent?: string;
  iconPack?: IconPackTypes;
  checkbox: CheckboxOrRadio;
  radio: CheckboxOrRadio;
  switch: Switch;
  autocomplete: Autocomplete;
  inputitems: Inputitems;
  pagination: Pagination;
  slider: Slider;
  tabs: Tabs;
  table: Table;
  tooltip: Tooltip;
  steps: Steps;
  button: Button;
  menu: Menu;
  skeleton: Skeleton;
  notification: Notification;
  dropdown: Dropdown;
  datepicker: Datepicker;
  modal: Modal;
  sidebar: Sidebar;
  loading: Loading;
  timepicker: Timepicker;
  carousel: Carousel;
  upload: Upload;
	// eslint-disable-next-line
};
