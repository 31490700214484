var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeMenu),expression:"closeMenu"}],staticClass:"navbar-item has-dropdown",class:{
		'is-hoverable': _vm.isHoverable,
		'is-active': _vm.newActive
	},on:{"mouseenter":_vm.checkHoverable}},[_c(_vm.tag,_vm._g(_vm._b({tag:"component",staticClass:"navbar-link",class:{
			'is-arrowless': _vm.arrowless,
			'is-active': _vm.newActive && _vm.collapsible
		},attrs:{"aria-haspopup":"true","tabindex":"0"},on:{"click":function($event){$event.preventDefault();return _vm.toggleMenu.apply(null, arguments)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.toggleMenu.apply(null, arguments)}}},'component',_vm.$attrs,false),_vm.$listeners),[(_vm.label)?[_vm._v(" "+_vm._s(_vm.label)+" ")]:_vm._t("label")],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.collapsible || (_vm.collapsible && _vm.newActive)),expression:"!collapsible || (collapsible && newActive)"}],staticClass:"navbar-dropdown",class:{
			'is-right': _vm.right,
			'is-boxed': _vm.boxed,
		}},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }