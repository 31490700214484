import Vue, { PropOptions } from 'vue';

export default Vue.extend({
	name: 'NavigationIconDropdown',
	props: {
		icon: {
			type: String,
			required: true,
		} as PropOptions<string>,
		headerLabel: {
			type: String,
			default: () => '',
		} as PropOptions<string>,
	},
	data() {
		return {
			active: false,
		};
	},
	watch: {
		$route() {
			(this.$refs.dropdown as any).closeMenu(); // eslint-disable-line
		},
	},
	methods: {
		handleLogout(): void {
			this.$emit('logout');
		},
		handleActiveChange(active: boolean): void {
			this.active = active;
			this.$emit('active-change', active);
		},
	},
});
