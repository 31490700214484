import { Vue as _Vue } from 'vue/types/vue';

import AccountsMenu from './components/accounts-menu/AccountsMenu.component.vue';
import ActiveGfIcon from './components/active-gf-icon/ActiveGfIcon.component.vue';
import BasicLayout from './components/basic-layout/BasicLayout.vue';
import BlandGfIcon from './components/bland-gf-icon/BlandGfIcon.component.vue';
import BlurOverlay from './components/blur-overlay/BlurOverlay.component.vue';
import BottomDrawer from './components/bottom-drawer/BottomDrawer.component.vue';
import DownloadBlock from './components/DownloadBlock.vue';
import ErrorBlock from './components/error-block/ErrorBlock.vue';
import FatCard from './components/fat-card/FatCard.vue';
import Flock from './components/Flock.vue';
import FooterUi from './components/Footer.vue';
import BankLevelSecurity from './components/footer/BankLevelSecurity.vue';
import BBBAccredited from './components/footer/BBBAccredited.vue';
import GfFieldNew from './components/gf-field-new/GfFieldNew.vue';
import GfCheckbox from './components/GfCheckbox.vue';
import GfFieldBody from './components/GfFieldBody.vue';
import GfLoading from './components/GfLoading.vue';
import GfLogo from './components/GfLogo.vue';
import GfLogoIcon from './components/GfLogoIcon.vue';
import GfMessage from './components/GfMessage.vue';
import GfNavbarDropdown from './components/GfNavbarDropdown.vue';
import GfNavbarItem from './components/GfNavbarItem.vue';
import GfRadio from './components/GfRadio.vue';
import HousingBanner from './components/housing-banner/housing-banner.component.vue';
import IconKey from './components/IconKey.vue';
import ImageHero from './components/ImageHero.vue';
import InfoBlock from './components/info-block/InfoBlock.vue';
import LendingNavbar from './components/lending-navbar/LendingNavbar.component.vue';
import MainNavigation from './components/main-navigation/MainNavigation.component.vue';
import MobilePagination from './components/MobilePagination.vue';
import NavbarIconDropdown from './components/navbar-icon-dropdown/NavbarIconDropdown.component.vue';
import Parallax from './components/Parallax.vue';
import PasswordStrength from './components/password-strength/PasswordStrength.vue';
import ValidationIndicator from './components/password-strength/ValidationIndicator.vue';
import SettingsMenu from './components/settings-menu/SettingsMenu.component.vue';
import StairsLogo from './components/StairsLogo.vue';
import StairsLogoIcon from './components/StairsLogoIcon.vue';
import TelephoneField from './components/telephone-field/TelephoneField.vue';
import TransitionalMenuItem from './components/transitional-menu-item/TransitionalMenuItem.component.vue';
import UserMenu from './components/user-menu/UserMenu.component.vue';
import V3Recaptcha from './components/V3Recaptcha.vue';

const version = '__VERSION__';

const install = (Vue: typeof _Vue) => {
	Vue.component('AccountsMenu', AccountsMenu);
	Vue.component('ActiveGfIcon', ActiveGfIcon);
	Vue.component('BankLevelSecurity', BankLevelSecurity);
	Vue.component('BbbAccredited', BBBAccredited);
	Vue.component('BlandGfIcon', BlandGfIcon);
	Vue.component('BlurOverlay', BlurOverlay);
	Vue.component('BottomDrawer', BottomDrawer);
	Vue.component('BasicLayout', BasicLayout);
	Vue.component('DownloadBlock', DownloadBlock);
	Vue.component('ErrorBlock', ErrorBlock);
	Vue.component('FatCard', FatCard);
	Vue.component('Flock', Flock);
	Vue.component('FooterUi', FooterUi);
	Vue.component('GfCheckbox', GfCheckbox);
	Vue.component('GfFieldBody', GfFieldBody);
	Vue.component('GfFieldNew', GfFieldNew);
	Vue.component('GfLoading', GfLoading);
	Vue.component('GfLogo', GfLogo);
	Vue.component('GfLogoIcon', GfLogoIcon);
	Vue.component('GfMessage', GfMessage);
	Vue.component('GfNavbarDropdown', GfNavbarDropdown);
	Vue.component('GfNavbarItem', GfNavbarItem);
	Vue.component('GfRadio', GfRadio);
	Vue.component('HousingBanner', HousingBanner);
	Vue.component('IconKey', IconKey);
	Vue.component('ImageHero', ImageHero);
	Vue.component('InfoBlock', InfoBlock);
	Vue.component('LendingNavbar', LendingNavbar);
	Vue.component('MainNavigation', MainNavigation);
	Vue.component('MobilePagination', MobilePagination);
	Vue.component('NavbarIconDropdown', NavbarIconDropdown);
	Vue.component('Parallax', Parallax);
	Vue.component('PasswordStrength', PasswordStrength);
	Vue.component('StairsLogo', StairsLogo);
	Vue.component('StairsLogoIcon', StairsLogoIcon);
	Vue.component('SettingsMenu', SettingsMenu);
	Vue.component('TelephoneField', TelephoneField);
	Vue.component('TransitionalMenuItem', TransitionalMenuItem);
	Vue.component('UserMenu', UserMenu);
	Vue.component('V3Recaptcha', V3Recaptcha);
	Vue.component('ValidationIndicator', ValidationIndicator);
};

export const plugin = {
	install,
	version,
};
