import Vue, { PropOptions } from 'vue';

import { AccountsMenuConfig, LendingNavbarConfig, UserMenuItem } from './../../types';

export default Vue.extend({
	name: 'LendingNavbar',
	props: {
		activeAccount: {
			type: Object,
			required: true,
		},
		user: {
			type: Object,
			required: true,
		},
		config: {
			type: Object,
			required: true,
		} as PropOptions<LendingNavbarConfig>,
		currentUrl: {
			type: String,
			required: true,
		} as PropOptions<string>,
		isMainMenuActive: {
			type: Boolean,
			required: true,
		} as PropOptions<boolean>,
		hideMainMenu: {
			type: Boolean,
			default: () => false,
		} as PropOptions<boolean>,
		allAccountsCreated: {
			type: Boolean,
			required: false,
			default: () => false,
		} as PropOptions<boolean>,
	},
	data() {
		return {
			isAccountsMenuActive: false,
			isUserMenuActive: false,
			accountsLabel: 'Switch Account',
		};
	},
	computed: {
		userMenuItems(): UserMenuItem[] {
			return [
				{
					label: 'Personal Information',
					url: this.config.personalInfoUrl,
					routeName: this.config.personalInfoRouteName,
				},
				{
					label: 'Security',
					url: this.config.securityUrl,
					routeName: this.config.securityRouteName,
				},
				{
					label: 'Communication Preferences',
					url: this.config.comPrefsUrl,
					routeName: this.config.comPrefsRouteName,
				},
				{
					label: 'Notifications',
					url: this.config.notificationsUrl,
					routeName: this.config.notificationsRouteName,
				},
			];
		},
		accountsMenuConfig(): AccountsMenuConfig {
			return {
				investorMarketingUrl: this.config.investorMarketingUrl,
				iraMarketingUrl: this.config.iraMarketingUrl,
				borrowerMarketingUrl: this.config.borrowerMarketingUrl,
				brokerMarketingUrl: this.config.brokerMarketingUrl,
			};
		},
		// This computed exists to power the watcher
		isAnyMenuActive(): boolean {
			return this.isMainMenuActive || this.isUserMenuActive || this.isAccountsMenuActive;
		},
	},
	watch: {
		isAnyMenuActive() {
			this.$emit('any-active-change', this.isAnyMenuActive);
		},
	},
	methods: {
		handleSwitch(account: any) {
			this.$emit('switch', account);
		},
		handleLogout() {
			this.$emit('logout');
		},
		toggleMainMenu(): void {
			this.$emit('main-active-change', !this.isMainMenuActive);
		},
		toggleAccountsMenu(): void {
			this.isUserMenuActive = false;
			this.isAccountsMenuActive = !this.isAccountsMenuActive;
		},
		toggleUserMenu(): void {
			this.isAccountsMenuActive = false;
			this.isUserMenuActive = !this.isUserMenuActive;
		},
		handleUserActiveChange(active: boolean): void {
			// Hack to deal with internal dropdown clickoff logic conflicting with mobile drawer
			if (this.config.isDesktop || active) {
				this.isUserMenuActive = active;
			}
		},
		handleAccountsActiveChange(active: boolean): void {
			// Hack to deal with internal dropdown clickoff logic conflicting with mobile drawer
			if (this.config.isDesktop || active) {
				this.isAccountsMenuActive = active;
			}
		},
		handleHelp(): void {
			this.$emit('help');
		},
	},
});
