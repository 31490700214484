import Vue, { PropOptions } from 'vue';

export default Vue.extend({
	name: 'BottomDrawer',
	props: {
		active: {
			type: Boolean,
			required: true,
		} as PropOptions<boolean>,
		headerLabel: {
			type: String,
			default: () => '',
		} as PropOptions<string>,
	},
	methods: {
		handleClose(): void {
			this.$emit('close');
		},
	},
});
