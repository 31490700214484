
import Vue, { PropOptions } from 'vue'; // eslint-disable-line no-unused-vars

export default Vue.extend({
	name: 'GfFieldNew',
	props: {
		variant: {
			type: [String, Object],
			required: false,
			default: '',
		} as PropOptions<string | object>,
		label: {
			type: String,
			required: false,
			default: '',
		} as PropOptions<string>,
		sublabel: {
			type: String,
			required: false,
			default: '',
		} as PropOptions<string>,
		labelFor: {
			type: String,
			required: false,
			default: '',
		} as PropOptions<string>,
		message: {
			type: [String, Array, Object],
			required: false,
			default: '',
		} as PropOptions<string | string[] | {[key: string]: string}[]>,
		grouped: {
			type: Boolean,
			required: false,
			default: false,
		} as PropOptions<boolean>,
		groupMultiline: {
			type: Boolean,
			required: false,
			default: false,
		} as PropOptions<boolean>,
		position: {
			type: String,
			required: false,
			default: '',
		} as PropOptions<string>,
		expanded: {
			type: Boolean,
			required: false,
			default: false,
		} as PropOptions<boolean>,
		horizontal: {
			type: Boolean,
			required: false,
			default: false,
		} as PropOptions<boolean>,
		addons: {
			type: Boolean,
			required: false,
			default: true,
		} as PropOptions<boolean>,
		customClass: {
			type: String,
			required: false,
			default: '',
		} as PropOptions<string>,
		labelPosition: {
			type: String,
			required: false,
			default: '',
		} as PropOptions<string>,
	},
});

