
import Vue from 'vue';

// For some reason eslint doesn't detect this usage. Go figure.
/* eslint-disable no-unused-vars */
enum thresholds {
	strong = 1,
	okay = 0.8,
	dobetter = 0.6,
}
/* eslint-enable no-unused-vars */

export default Vue.extend({
	name: 'SimpleStrengthMeter',
	props: {
		// Should be a value between 0 and 1
		score: {
			type: Number,
			required: true,
		},
	},
	computed: {
		progressType(): string {
			if (this.score <= 0) {
				return '';
			} else if (this.score >= thresholds.strong) {
				return 'is-success';
			} else if (this.score >= thresholds.okay) {
				return 'is-info';
			} else if (this.score >= thresholds.dobetter) {
				return 'is-warning';
			} else {
				return 'is-danger';
			}
		},
		legend(): string {
			if (this.score <= 0) {
				return '';
			} else if (this.score >= thresholds.strong) {
				return 'Strong';
			} else if (this.score >= thresholds.okay) {
				return 'Okay';
			} else if (this.score >= thresholds.dobetter) {
				return 'Needs Improvement';
			} else {
				return 'Weak';
			}
		},
		label(): string {
			return (this.legend ? ` ${ this.legend }` : '');
		},
	},
});
