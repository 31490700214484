import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue, { PropOptions } from 'vue';

import {
	EXTERNAL_URLS,
	PATHS,
} from '../constants';
import { FormattedFooterData, NavItemData, GfSnsData, GfLrosData } from '../types/general.types';
import { getDomainUrl } from '../utils';

import BankLevelSecurity from './footer/BankLevelSecurity.vue';
import BbbAccredited from './footer/BBBAccredited.vue';
import GfLogo from './GfLogo.vue';

export default Vue.extend({
	name: 'FooterUi',
	components: {
		GfLogo,
		FontAwesomeIcon,
		BbbAccredited,
		BankLevelSecurity,
	},
	props: {
		railsDomain: {
			type: String,
			required: true,
		} as PropOptions<string>,
		gmkDomain: {
			type: String,
			required: true,
		} as PropOptions<string>,
	},
	computed: {
		formattedFooterData(): FormattedFooterData {
			const { railsDomain, gmkDomain } = this;

			const GF_LROS_ITEMS: GfLrosData[] = [
				{
					label: 'SEC Filings',
					url: EXTERNAL_URLS.SEC_FILINGS_URL,
				},
			];

			const GF_SNS_ITEMS: GfSnsData[] = [
				{
					iconName: 'facebook',
					url: EXTERNAL_URLS.FACEBOOK_URL,
					alt: 'GROUNDFLOOR Facebook',
				},
				{
					iconName: 'twitter',
					url: EXTERNAL_URLS.TWITTER_URL,
					alt: 'GROUNDFLOOR Twitter',
				},
				{
					iconName: 'linkedin',
					url: EXTERNAL_URLS.LINKEDIN_URL,
					alt: 'GROUNDFLOOR Linkedin',
				},
				{
					iconName: 'instagram',
					url: EXTERNAL_URLS.INSTAGRAM_URL,
					alt: 'GROUNDFLOOR Instagram',
				},
				{
					iconName: 'youtube',
					url: EXTERNAL_URLS.YOUTUBE_URL,
					alt: 'GROUNDFLOOR Youtube',
				},

			];

			const FOOTER_INVEST_ITEMS: NavItemData[] = [
				{
					label: 'Funding',
					url: getDomainUrl(railsDomain, EXTERNAL_URLS.FUNDING_URL),
				},
				{
					label: 'Funded',
					url: getDomainUrl(railsDomain, EXTERNAL_URLS.FUNDED_URL),
				},
				{
					label: 'Repaid',
					url: getDomainUrl(railsDomain, EXTERNAL_URLS.REPAID_URL),
				},
				{
					label: 'Investor Agreement',
					url: EXTERNAL_URLS.INVESTOR_AGREEMENT_URL,
				},
				{
					label: 'SEC Filings',
					url: EXTERNAL_URLS.SEC_FILINGS_URL,
				},
			];

			const FOOTER_BORROW_ITEMS: NavItemData[] = [
				{
					label: 'Borrowers',
					url: EXTERNAL_URLS.BORROWERS_URL,
				},
				{
					label: 'Brokers',
					url: getDomainUrl(railsDomain, EXTERNAL_URLS.BROKERS_URL),
				},
			];

			const FOOTER_LEARN_ITEMS: NavItemData[] = [
				{
					label: 'FAQ',
					url: EXTERNAL_URLS.FAQ_URL,
				},
				{
					label: 'Support',
					url: EXTERNAL_URLS.SUPPORT_URL,
				},
				{
					label: 'Blog',
					url: EXTERNAL_URLS.BLOG_URL,
				},
				{
					label: 'Offering Circular',
					url: EXTERNAL_URLS.OFFERING_CIRCULAR_URL,
				},
				{
					label: 'Terms Of Service',
					url: getDomainUrl(gmkDomain, PATHS.TERMS_OF_SERVICE_PATH),
				},
				{
					label: 'Privacy Policy',
					url: getDomainUrl(gmkDomain, PATHS.PRIVACY_POLICY_PATH),
				},
			];
			const FOOTER_ABOUT_ITEMS: NavItemData[] = [
				{
					label: 'Team',
					url: getDomainUrl(gmkDomain, PATHS.TEAM_PATH),
				},
				{
					label: 'Culture',
					url: getDomainUrl(gmkDomain, PATHS.CULTURE_PATH),
				},
				{
					label: 'Press',
					url: EXTERNAL_URLS.PRESS_URL,
				},
				{
					label: 'Contact',
					url: getDomainUrl(gmkDomain, PATHS.CONTACT_PATH),
				},
				{
					label: 'Jobs',
					url: EXTERNAL_URLS.OPEN_ROLES_URL,
				},
			];

			const formattedData = {
				investLabel: 'Invest',
				learnLabel: 'Learn',
				aboutLabel: 'About',
				borrowLabel: 'Borrow',
				gfLrosItems: GF_LROS_ITEMS,
				gfSnsItems: GF_SNS_ITEMS,
				footerInvestItems: FOOTER_INVEST_ITEMS,
				footerBorrowItems: FOOTER_BORROW_ITEMS,
				footerLearnItems: FOOTER_LEARN_ITEMS,
				footerAboutItems: FOOTER_ABOUT_ITEMS,
				bbbUrl: EXTERNAL_URLS.BBB_URL,
				secUrl: EXTERNAL_URLS.SEC_URL,
				amendmentsPath: getDomainUrl(gmkDomain, PATHS.AMENDMENTS_PATH),
			};

			return formattedData;
		},
	},
});
