import { LD_ACCOUNT_TYPES, LD_LABELED_ACCOUNT_TYPES } from '@gffinance/conduit';

export function getAccountLabel(account: any): string {
	return account.nickname || LD_LABELED_ACCOUNT_TYPES.get(account.type) || '';
}

export function getUserAccountByType(user: any, type: any): any | null {
	const accounts = user.accounts.filter((account: any) => account.type === type);
	return accounts[0] ? accounts[0] : null;
}

export function userHasAccountOfType(user: any, type: LD_ACCOUNT_TYPES): boolean {
	return !!getUserAccountByType(user, type);
}

export function stripAccountIdPrefix(accountId: string): string {
	const split = accountId.split('_');

	return split[1] || '';
}
