
import Vue, { PropOptions } from 'vue';// eslint-disable-line

export default Vue.extend({
	name: 'ValidationIndicator',
	props: {
		label: {
			type: String,
			required: true,
		} as PropOptions<string>,
		isValid: {
			type: Boolean,
			required: true,
		} as PropOptions<boolean>,
		muteErrors: {
			type: Boolean,
			default: () => false,
		} as PropOptions<boolean>,
	},
	computed: {
		validationClass(): string {
			if (this.isValid) {
				return 'is-success';
			} else if (this.muteErrors) {
				return 'is-muted';
			} else {
				return 'is-danger';
			}
		},
	},
});
