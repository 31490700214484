
import Vue from 'vue';

import { HUBSPOT_HOUSING_MARKET_URL } from './../../constants/urls';

export default Vue.extend({
	name: 'HousingBanner',
	props: {
		shown: {
			type: Boolean,
			required: true,
			default: false,
		},
	},
	data() {
		return {
			HUBSPOT_HOUSING_MARKET_URL,
		};
	},
});
