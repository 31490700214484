import {
	LdAccount,
	ldBorrowerAccountMock,
	ldBrokerAccountMock,
	ldUserBorrowerMock,
	ldUserBrokerMock,
} from '@gffinance/conduit';
import Vue from 'vue';

import { LendingNavbarConfig, LendingNavigationConfig } from './../../../src/types';
import { namedRoutes } from './../../router';

// This function violates separation of concerns but is sadly necessary here. Don't repeat this pattern unless there is no other choice.
function isDesktop(): boolean {
	return window.innerWidth >= 1024;
}

const FAKE_LWA_DOMAIN = 'lending.fakegf.com';
const FAKE_IWA_DOMAIN = 'app.fakegf.com';
const FAKE_RAILS_DOMAIN = 'www.fakegf.com';
const FAKE_GMK_DOMAIN = 'fakegf.com';

function fakeIwaUrl(path: string): string {
	return `https://${ FAKE_IWA_DOMAIN }${ path }`;
}

function fakeRailsUrl(path: string): string {
	return `https://${ FAKE_RAILS_DOMAIN }${ path }`;
}

function fakeLwaUrl(path: string): string {
	return `https://${ FAKE_LWA_DOMAIN }${ path }`;
}

function fakeGmkUrl(path: string): string {
	return `https://${ FAKE_GMK_DOMAIN }${ path }`;
}

export default Vue.extend({
	name: 'LendingLayout',
	data() {
		return {
			IWA_ROOT_URL: fakeIwaUrl('/'),
			namedRoutes,
			isMainMenuActive: isDesktop(),
			railsDomain: FAKE_RAILS_DOMAIN,
			gmkDomain: FAKE_GMK_DOMAIN,
			RAILS_BORROWER_PROFILE_URL: fakeRailsUrl('/borrower-profile'),
			user: ldUserBrokerMock,
			activeAccount: ldBrokerAccountMock,
		};
	},
	computed: {
		lendingNavigationConfig(): LendingNavigationConfig {
			return {
				brokerProfileUrl: fakeRailsUrl('/broker-profile'),
				dashboardUrl: fakeLwaUrl('/'),
				draftsUrl: fakeLwaUrl('/drafts'),
				submittedUrl: fakeLwaUrl('/submitted'),
				activeUrl: fakeLwaUrl('/active'),
				closedUrl: fakeLwaUrl('/closed'),
			};
		},
		lendingNavbarConfig(): LendingNavbarConfig {
			return {
				homeUrl: fakeLwaUrl('/'),
				investorMarketingUrl: fakeGmkUrl('/investors'),
				iraMarketingUrl: fakeGmkUrl('/ira'),
				borrowerMarketingUrl: fakeGmkUrl('/borrowers'),
				brokerMarketingUrl: fakeGmkUrl('/brokers'),
				personalInfoUrl: fakeRailsUrl('/personal-info'),
				securityUrl: fakeRailsUrl('/security'),
				comPrefsUrl: fakeRailsUrl('/com-prefs'),
				notificationsUrl: fakeRailsUrl('/notifications'),
				isDesktop: isDesktop(),
			};
		},
		currentUrl(): string {
			return `${ window.location.protocol }//${ window.location.host }${ this.$route.path }`;
		},
		hideMainMenu(): boolean {
			if (!this.$route.meta) return true;
			return !!this.$route.meta.hideMainMenu;
		},
	},
	 watch: {
		$route() {
			if (!isDesktop()) {
				this.isMainMenuActive = false;
			}
		},
	},
	methods: {
		handleSwitch(account: LdAccount) {
			console.warn('???swtich', account);
		},
		handleLogout() {
			console.warn('???logout');
		},
		toggleMainMenu(): void {
			this.isMainMenuActive = !this.isMainMenuActive;
		},
		handleMainActiveChange(active: boolean): void {
			this.isMainMenuActive = active;
		},
		handleAnyActiveChange(active: boolean): void {
			if (active && !isDesktop()) {
				document.documentElement.classList.add('is-clipped');
			} else {
				document.documentElement.classList.remove('is-clipped');
			}
		},
		handleHelp(): void {
			console.warn('???help');
		},
	},
});
