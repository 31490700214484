
import Vue, { PropOptions } from 'vue'; // eslint-disable-line

export default Vue.extend({
	name: 'MobilePagination',
	props: {
		total: {
			type: Number,
			required: true,
		} as PropOptions<number>,
		current: {
			type: Number,
			required: true,
		} as PropOptions<number>,
		perPage: {
			type: Number,
			required: true,
		} as PropOptions<number>,
	},
	computed: {
		totalPages():number {
			return Math.ceil(this.total / this.perPage);
		},
		disablePrevious(): boolean {
			return this.current <= 1;
		},
		disableNext(): boolean {
			return this.current >= this.totalPages;
		},
	},
	methods: {
		next(): void {
			this.change(this.current + 1);
		},
		previous(): void {
			this.change(this.current - 1);
		},
		change(page: number): void {
			this.$emit('update:current', page);
			this.$emit('change', page);
		},
	},
});
