import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Oruga from '@oruga-ui/oruga';
import Buefy from 'buefy';
import Vue from 'vue';
import Vuelidate from 'vuelidate';

// This ensures that our global bulma css is rendered before other css
import './assets/app.scss';

import { plugin, buefyConfig, orugaConfig } from '../src';

import App from './App.vue';
import router from './router';
import store from './store';
import './icons';

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.use(Oruga, orugaConfig);
Vue.use(Buefy, buefyConfig);
Vue.use(plugin);
Vue.use(Vuelidate);

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app');
