
<script>
export default {
	name: 'BFieldBody',
	props: {
		message: {
			type: [String, Array],
			default: '',
			required: false,
		},
		type: {
			type: [String, Object],
			default: '',
			required: false,
		},
	},
	render(createElement) {
		let first = true;
		return createElement('div', { attrs: { class: 'field-body' } }, this.$slots.default.map((element) => {
			// skip returns and comments
			if (!element.tag) {
				return element;
			}
			let message;
			if (first) {
				message = this.message;
				first = false;
			}
			return createElement('gf-field', { attrs: { type: this.type, message } }, [element]);
		}));
	},
};
</script>
