// Adapted from buefy's clickOutside.js and modified to support the shadow dom
// I've intentionally kept the typescript as light as possible. Some of this code is a bit mysterious but is known to work. Trying to "fix" it with typescript didn't seems like the best option.
import { DirectiveFunction } from 'vue/types/options';

interface sdcoInstance {
	el: HTMLElement;
	eventHandlers: DocumentAndElementEventHandlers;
}

const isTouch =
	typeof window !== 'undefined' && ('ontouchstart' in window || navigator.maxTouchPoints > 0);
const events = isTouch ? ['touchstart', 'click'] : ['click'];

const instances: sdcoInstance[] = [];

function processArgs(bindingValue: any) {
	const isFunction = typeof bindingValue === 'function';
	if (!isFunction && typeof bindingValue !== 'object') {
		throw new Error(`v-click-outside: Binding value should be a function or an object, ${ typeof bindingValue } given`);
	}

	return {
		handler: isFunction ? bindingValue : bindingValue.handler,
		middleware: bindingValue.middleware || ((isClickOutside: any) => isClickOutside),
		events: bindingValue.events || events,
	};
}

function onEvent({ el, event, handler, middleware }: any) {
	const isClickOutside = (
		event.target !== el
		&& !el.contains(event.target)
		// If the component with the directive is inside a shadow dom the listener we have attached in the light dom will think the click is coming from the shadow dom root element rather than the element itself.
		&& (
			!isShadowChild(el)
			|| event.target !== el.getRootNode().host
		)
	);

	if (!isClickOutside || !middleware(event, el)) {
		return;
	}

	handler(event, el);
}

function toggleEventListeners({ eventHandlers }: any = {}, action = 'add') {
	if (Array.isArray(eventHandlers)) {
		eventHandlers.forEach(({ event, handler, el }: any) => {
			const methodKey = `${ action }EventListener`;
			// eslint-disable-next-line
			// @ts-ignore
			document[methodKey](event, handler);

			if (el && isShadowChild(el)) {
				el.getRootNode()[methodKey](event, handler);
			}
		});
	}
}

const bind:DirectiveFunction = function(el, { value }) {
	const { handler, middleware, events } = processArgs(value);

	const instance: sdcoInstance = {
		el,
		eventHandlers: events.map((eventName: string) => ({
			event: eventName,
			handler: (event: Event) => onEvent({ event, el, handler, middleware }),
		})),
	};

	toggleEventListeners(instance, 'add');

	instances.push(instance);
};

const update:DirectiveFunction = function(el, { value }) {
	const { handler, middleware, events } = processArgs(value);
	// `filter` instead of `find` for compat with IE
	const instance = instances.filter((instance) => instance.el === el)[0];

	toggleEventListeners(instance, 'remove');

	instance.eventHandlers = events.map((eventName: any) => ({
		event: eventName,
		handler: (event: any) => onEvent({ event, el, handler, middleware }),
	}));

	toggleEventListeners(instance, 'add');
};

const unbind:DirectiveFunction = function(el) {
	// `filter` instead of `find` for compat with IE
	const instance = instances.filter((instance) => instance.el === el)[0];

	toggleEventListeners(instance, 'remove');
};

function isShadowChild(element: HTMLElement) {
	// Originally this function checked to see if element.getRootNode().host !== undefined. However, this turned out to be unreliable in situations where the element existed but hadn't yet been added to the shadow dom.
	return (element.getRootNode() !== document);
}

const directive = {
	bind,
	update,
	unbind,
	instances,
};

export default directive;
