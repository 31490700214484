import Vue, { PropOptions } from 'vue';

export default Vue.extend({
	name: 'BlurOverlay',
	props: {
		active: {
			type: Boolean,
			default: () => false,
		} as PropOptions<boolean>,
	},
	methods: {
		handleClick(): void {
			this.$emit('close');
		},
	},
});
